<template>
  <section class="bg-contrast-lower height-100vh">
    <div class="container padding-y-md max-width-lg">
      <div class="grid gap-xs justify-center">
        <div class="col-12 col-5@md padding-top-md">
          <div class="card__container padding-lg bg-white radius-md">
            <div class="text-component margin-bottom-md">
              <h2 class="margin-bottom-md text-md">
                Inloggen <span class="color-primary">ledenomgeving</span>
              </h2>

              <p class="color-contrast-medium text-sm">
                Login met OCG account om uw persoonlijke gegevens te beheren.
                Heeft u nog geen account? Vraag dan een account aan via het
                <router-link to="/registreren">registratieformulier</router-link
                >.
              </p>
            </div>

            <div class="grid gap-md justify-center">
              <div class="grid gap-xs">
                <div class="col-12">
                  <label class="form-label margin-bottom-xxs" for="email"
                    >E-mail adres</label
                  >
                  <input
                    class="form-control width-100%"
                    type="email"
                    name="email"
                    @input="error = ''"
                    v-model="email"
                    placeholder="naam@domein.nl"
                    id="email"
                    required
                  />
                </div>
                <div class="col-12" v-if="!isResetMode">
                  <label class="form-label margin-bottom-sm" for="password"
                    >Wachtwoord</label
                  >
                  <input
                    class="form-control width-100%"
                    type="password"
                    name="password"
                    @input="error = ''"
                    id="password"
                    data-validate="hasNumber"
                    v-model="password"
                    required
                  />
                </div>
                <div class="col-12">
                  <p class="color-error text-sm">{{ error }}</p>
                </div>
                <div class="col-12">
                  <p v-if="!isResetMode">
                    <button
                      @click="signIn()"
                      class="btn btn--primary btn--md width-100% margin-y-xs"
                    >
                      Inloggen
                    </button>
                  </p>
                  <p v-else>
                    <button
                      @click="resetPassword()"
                      class="btn btn--primary btn--md width-100% margin-y-xs"
                    >
                      Verstuur wachtwoord reset link
                    </button>
                  </p>
                  <p class="text-center">
                    <a
                      href="#"
                      @click.prevent="toggleResetMode"
                      class="text-sm color-primary"
                      >{{
                        isResetMode
                          ? "Terug naar inloggen"
                          : "Wachtwoord vergeten?"
                      }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Login",

  mounted() {
    // if (this.$isAuthenticated()) {
    //   this.$router.push("/dashboard");
    // }
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      isResetMode: false,
    };
  },
  methods: {
    toggleResetMode() {
      this.isResetMode = !this.isResetMode;
      this.error = "";
    },

    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;

          if (user.emailVerified == false) {
            firebase.auth().signOut();
            this.error = "Uw e-mailadres is nog niet geverifieerd.";
          }
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(error);
          this.error = "Uw e-mailadres of wachtwoord is onjuist.";
        });
    },

    resetPassword() {
      if (!this.email) {
        this.error =
          "Vul uw e-mailadres in om een wachtwoord reset link te ontvangen.";
        return;
      }

      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.error = "";
          this.isResetMode = false;
          alert(
            "Er is een wachtwoord reset link verzonden naar uw e-mailadres."
          );
        })
        .catch((error) => {
          console.log(error);
          this.error = "Er is een fout opgetreden. Controleer uw e-mailadres.";
        });
    },

    resetLoginForm() {
      this.email = "";
      this.emailSent = false;
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped>
.bg--fullscreen {
  height: calc(100vh);
  width: 100vw;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
